import { graphql } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Glide from '@glidejs/glide';

import { addAuthentication } from '../../components/addAuthentication';
import {
  DashboardMenuCard,
  DashboardPlanInformation,
} from '../../components/dashboard';
import './scss/Dashboard.scss';
import { ResourceCenter, GettingStarted } from '../../components/icons';
import { DashboardLayout } from '../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice | Dashboard</title>
    </Helmet>
    <DashboardLayout>
      <div className="Dashboard">
        <DashboardPlanInformation />
        <DashboardCards />

        <div className="Dashboard-support">
          <h3 className="Dashboard-support-headline">Need Help?</h3>
          <p className="Dashboard-support-copy">
            Please email{' '}
            <a href="emailto:private.practice@amwell.com">
              private.practice@amwell.com
            </a>{' '}
            for assistance
          </p>
        </div>
      </div>
    </DashboardLayout>
  </>
));

const DashboardCards = () => {
  const glideRef = useRef(null);
  const slider = useRef(null);
  const sliderConfiguration = {
    draggable: true,
    dots: '.glide__bullets',
    type: 'carousel',
    perView: 1,
  };
  const slides = [
    <DashboardMenuCard
      icon={ResourceCenter}
      headline="Resource Center"
      copy="Access training resources, patient outreach materials, reimbursement guidelines and more."
      url="https://info.amwell.com/Amwell-Private-Practice-Training.html"
    />,
    <DashboardMenuCard
      icon={GettingStarted}
      headline="Getting Started with Amwell"
      copy="Launch the Amwell telehealth experience here if you are a provider user."
      url="https://privatepractice.amwellnow.com/now"
    />,
  ];

  useEffect(() => {
    if (glideRef.current) {
      slider.current = new Glide('.glide', sliderConfiguration);
      slider.current.mount();
    }
  });

  return (
    <>
      <div className="Dashboard-cards desktop">
        {slides.map((Card, i) => (
          <React.Fragment key={`card-${i}`}>{Card}</React.Fragment>
        ))}
      </div>

      <div className="Dashboard-cards glide mobile" ref={glideRef}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {slides.map((Card, i) => (
              <li className="glide__slide" key={`slide-${i}`}>
                {Card}
              </li>
            ))}
          </ul>
        </div>
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {slides.map((_, i) => (
            <button
              className="glide__bullet"
              data-glide-dir={`=${i}`}
              aria-label="slide bullet"
              key={`bullet-${i}`}
            ></button>
          ))}
        </div>
      </div>
    </>
  );
};
